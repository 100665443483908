<template>
  <div class="boothReserveMobile">
    <div class="areaDomContent">
      <div class="areaDom" id="areaDom">
        <boothMap :selected="mapBooth"/>
      </div>
    </div>
    <div class="headerColor">
      <div class="colorAndText">
        <div class="color red fl"></div>
        <div class="text fl">已售展台</div>
      </div>
      <div class="colorAndText">
        <div class="color green fl"></div>
        <div class="text fl">我的展台</div>
      </div>
    </div>
    <div class="contentBox">
      <div class="TitBox">
        <div class="left">
          <span class="gang"></span>
          <span class="t_label">预定信息</span>
        </div>
      </div>
      <div class="msgBox">
        <div class="msgItem">
          <span class="label">联系人</span>
          <span class="txt">{{ msg.username | textFormat }}</span>
        </div>
        <div class="msgItem">
          <span class="label">职位</span>
          <span class="txt">{{ msg.position | textFormat }}</span>
        </div>
        <div class="msgItem">
          <span class="label">公司</span>
          <span class="txt">{{ msg.company | textFormat }}</span>
        </div>
        <div class="msgItem">
          <span class="label">手机号码</span>
          <span class="txt">{{ msg.mobile | textFormat }}</span>
        </div>
        <div class="msgItem">
          <span class="label">邮箱</span>
          <span class="txt">{{ msg.email | textFormat }}</span>
        </div>
        <div class="msgItem">
          <span class="label">预定时间</span>
          <span class="txt">{{ msg.updated_time | secondFormat("LLL") }}</span>
        </div>
      </div>
    </div>
    <div class="contentBox" style="margin-top:0">
      <div class="TitBox">
        <div class="left">
          <span class="gang"></span>
          <span class="t_label">展位信息</span>
        </div>
        <div class="reBooking" @click="handleBooking">再次预定</div>
      </div>
      <div class="boothBox">
        <div class="boothItem" v-for="(item,i) in selectBooth" :key='i'>
          <span>{{ item.booth_no }} ( {{ item.name }} {{ item.price_cny||('$'+item.price_usd) }}元/展位 ) </span>
          <div v-if="item.status == 0" class="yellow">预定中</div>
          <div v-if="item.status == 1" class="green">预定成功</div>
          <div v-if="item.status == 2" class="gray">预定失败</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import boothMap from "~exp/components/booth/boothMap";

export default {
  name: "index2023",
  metaInfo: {
    title: '预定展位'
  },
  components: {
    boothMap,
  },
  data() {
    return {
      user_id: 53111,
      userInfo: {},
      selectBooth: [],
      mapBooth:[],
      alreadySelect: [],
      loading: false,
      msg:{}
    }
  },
  mounted() {
    if (this.$route.query.user_id) {
      this.user_id = this.$route.query.user_id
    }
    this.initAllInfo();
    this.getDetail()

  },
  methods: {
    handleBooking(){
      wx.miniProgram.redirectTo({
        url: `/overtPackage/pages/booking/index`,
      })
    },
    async getDetail(){
      let res = await this.$store.dispatch('booth/getBoothBook', {
        user_id: this.user_id,
        meeting_id:71
      })
      this.msg = res.data.length && res.data[0]
      this.selectBooth=res.data.length && res.data[0].booth||[]
      this.mapBooth = this.selectBooth.filter(item => item.status!=2)
    },
    async initAllInfo() {
      await this.getUserInfo()
      this.initPinchzoom()
    },
    async getUserInfo() {
      let res = await this.$store.dispatch('register/getboothUserInfo', {
        user_id: this.user_id
      })
    },
    initPinchzoom() {
      this.$nextTick(() => {
        $("div.areaDom").each(function () {
          new RTP.PinchZoom($(this), {});
        });
      });
    },
   
    
  }
}
</script>

<style scoped lang="less">
.red {
  color: #FF0000;
}

.boothReserveMobile {
  width: 100%;
  min-height: 100vh;
  background: #f8f8f8;
  padding-bottom: 100px;

  .areaDomContent {
    padding: 0 15px;
  }

  .headerColor {
    width: 100%;
    height: 50px;
    background-color: #fefefe;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 15px;

    .colorAndText {
      margin-right: 8px;

      &:last-child {
        margin-right: 0;
      }
    }

    .text {
      font-size: 14px;
    }

    .color {
      width: 18px;
      height: 18px;
      margin-right: 8px;
      text-align: center;
      line-height: 18px;
      font-size: 18px;
      font-weight: bold;

      &.red {
        background: #ffd966;
      }

      &.green {
        background: #ff5a5e;
      }
    }
  }

  .form {
    background-color: #fff;
    padding: 0 15px;
    margin-top: 10px;

    /deep/ .el-form-item__content {
      line-height: 30px;
    }

    /deep/ .el-form-item {
      border-bottom: 1px solid #ebedf0;
      margin-bottom: 5px;

      &:last-child {
        border-bottom: none;
      }
    }

    /deep/ .el-input__inner {
      border-color: transparent;
      text-align: right;
      padding-right: 0;
    }

    .booth {
      .addBooth {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-size: 14px;
        color: #c8c9cc;
        //padding-right: 15px;

        img {
          width: 14px;
          height: 14px;
          margin-right: 5px;
        }
      }

      .select {
        min-height: 50px;
        margin-top: 20px;

        .select-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 20px;
          background: #F0F5FF;
          border-radius: 10px;
          margin-bottom: 10px;
          color: #4F88F7;

          img {
            width: 14px;
            height: 14px;
          }
        }
      }
    }
  }

  .control-btn {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background: #fff;
    color: #000;
    font-size: 18px;
    text-align: center;
    line-height: 50px;
    height: calc(50px + constant(safe-area-inset-bottom));
    height: calc(50px + env(safe-area-inset-bottom));
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    font-size: 14px;
  }
}
.contentBox{
  margin: 12px;
  box-shadow: 0px 1px 3px 0px rgba(198,198,198,0.3);
  .TitBox{
    background: #fff;
    border-top-right-radius:8px;
    border-top-left-radius:8px;
    height: 40px;
    padding-left: 12px;
    padding-right: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom:1px solid #E8E8E8;
    .left{
      display: flex;
      align-items: center;
    }
    .gang{
      width: 3px;
      height: 15px;
      background: #4F88F7;
      border-radius: 2px;
      margin-right: 10px;
    }
    .t_label{
      font-size: 15px;
      font-weight: 400;
      color: #333333;
    }
    .reBooking{
      color: #4F88F7;
    }
  }
  .msgBox{
    padding-top: 23px;
    padding-left:12px;
    background: #fff;
    border-bottom-right-radius:8px;
    border-bottom-left-radius:8px;
    .msgItem{
      display: flex;
      align-items: center;
      padding-bottom: 20px;
      .label{
        width: 90px;
        font-size: 15px;
        font-weight: 400;
        color: #999999;
      }
    }
  }
  .boothBox{
    padding:12px;
    padding-top:24px;
    padding-bottom: 0px;
    background: #fff;
    border-bottom-right-radius:8px;
    border-bottom-left-radius:8px;
    .boothItem{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 24px;
      .span{
        font-size: 15px;
        font-weight: 400;
        color: #333333;
      }
      div{
        width: 57px;
        height: 19px;
        border-radius: 5px;
        line-height: 19px;
        text-align: center;
        font-size: 11px;
        font-weight: 400;
        color: #FFFFFF;

      }
      .yellow{
        background: #FF9800;
      }
      .green{
        background: #14BA62;
      }
      .gray{
        background: #B6B6B6;
      }
    }
  }
}
</style>
